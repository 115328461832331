<script setup lang="ts">
defineOptions({
  name: 'HomePage',
});

const loading = ref({
  loading: true,
});
const classNameRef = ref(['']);
const { $storyblokLivePreview, $cookie, $mopI18n, $gtm2 } = useNuxtApp();
const { getCmsStory, cmsStoryModelRef } = useMopCms('home');
const { initTransition } = useMopPageTransitionClient();
const { handleHeadForCmsPage, getHomepageSchemas } = useMopSeo();
const route = useRoute();

initTransition(classNameRef, loading);
$gtm2.trackPageView({
  pageType: 'home',
  pageCategoryId: 'homepage',
});

onMounted(() => {
  const topCategoryId: string = $cookie.get(constants.COOKIE.TOP_LEVEL_CATEGORY);
  // if filled needs to be CT UUID
  if (!topCategoryId || topCategoryId.length !== 36) {
    loading.value.loading = false;
    return;
  }
  performRedirectToTopLevelCategory(topCategoryId);
});

async function performRedirectToTopLevelCategory(topCategoryId: string) {
  const { searchCategoryById, categoryModelRef } = useMopCategories();
  await searchCategoryById(topCategoryId);
  if (!categoryModelRef.value.isInitialized()) {
    return false;
  }
  return useRouter().replace({
    path: $mopI18n.localePath(categoryModelRef.value.getUrl()),
    query: route.query,
  });
}

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return;
  }
  return handleHeadForCmsPage(cmsStoryModelRef.value);
});

useHead({
  title: () => seoHeadRef.value?.title as any,
  meta: () => seoHeadRef.value?.meta as any,
  link: () => seoHeadRef.value?.link as any,
  script: getHomepageSchemas(),
});

useAsyncData(async () => {
  await getCmsStory('shop');
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef, true);
</script>

<template>
  <div :class="['home', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <ClientOnly>
        <MopCmsContentElementsSlot
          v-if="!loading.loading && cmsStoryModelRef"
          id="homepage__content"
          :elements="cmsStoryModelRef.getContentElements()"
          :campaign-name="cmsStoryModelRef.getCampaignName()"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<style scoped lang="scss">
.home {
  position: relative;
  margin-bottom: $space60;
}
</style>
